























import { Vue, Component } from "vue-property-decorator";
import { Brand, Brands } from "@bit/planetadeleste.shopaholic-mc.shopaholic";
import SectionContent from "@/components/common/SectionContent.vue";
import { SwiperOptions } from "swiper";

@Component({
  components: {
    SectionContent
  }
})
export default class BrandsCarousel extends Vue {
  brands: Brands = new Brands();

  obSwiperOptions: SwiperOptions = {
    roundLengths: true,
    effect: "slide",
    height: 80,
    autoHeight: false,
    navigation: {
      nextEl: ".controls .button-next",
      prevEl: ".controls .button-prev"
    },
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
      480: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      600: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      960: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: 30
      }
    }
  };

  mounted() {
    this.load();
  }

  async load() {
    await this.brands.fetch();
  }

  slideStyle(obBrand: Brand) {
    return {
      "background-image": `url("${obBrand.preview_image}")`
    };
  }
}
